import { CommonModule } from '@angular/common';
import { Component, forwardRef, input } from '@angular/core';
import { FormsModule, NG_VALUE_ACCESSOR } from '@angular/forms';
import { PasswordModule } from 'primeng/password';
import { ControlComponent } from '../base/control/control.component';
import { FieldContainerComponent } from '../base/field-container/field-container.component';
@Component({
  selector: 'core-password',

  imports: [CommonModule, PasswordModule, FieldContainerComponent, FormsModule],
  templateUrl: './password.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => PasswordComponent),
      multi: true,
    },
  ],
})
export class PasswordComponent extends ControlComponent {
  feedback = input(false);
}
